import Cookies from 'js-cookie'

const TokenKey = 'Admin-Token'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  if (process.env.VUE_APP_DOMAIN) {
    return Cookies.set(TokenKey, 'houlang-token ' + token, { domain: process.env.VUE_APP_DOMAIN_CHILDREN.includes(document.domain) ? document.domain : process.env.VUE_APP_DOMAIN, expires: 1 })
    // return Cookies.set(TokenKey, "houlang-token " + token, "/", process.env.VUE_APP_DOMAIN);
  } else {
    return Cookies.set(TokenKey, 'houlang-token ' + token)
  }
}
export function removeToken() {
  if (process.env.VUE_APP_DOMAIN) {
    return Cookies.remove(TokenKey, { domain: process.env.VUE_APP_DOMAIN_CHILDREN.includes(document.domain) ? document.domain : process.env.VUE_APP_DOMAIN })
    // return Cookies.remove(TokenKey, "/", process.env.VUE_APP_DOMAIN);
  } else {
    return Cookies.remove(TokenKey)
  }
}
