<template>
  <div id="chang-pw">
    <div class="timer">{{ time }}</div>
    <div class="chang-pw-box">
      <div class="chang-pw-title">
        <span class="title">修改密码</span>
      </div>
      <a-form ref="userForm" :model="userForm" :rules="rules" class="demo-ruleForm" v-bind="formItemLayout">
        <a-alert message="密码长度为6-12位，必须包含至少一位大写字母、一位小写字母和数字" type="warning" style="margin-bottom: 10px;"/>
        <a-form-item label="新密码" name="password">
          <a-input-password v-model:value="userForm.password" autocomplete="off"/>
          <!-- <a-popover placement="right">
            <template #content>
              <span>密码长度为6-12位，必须包含至少一位大写字母、一位小写字母和数字</span>
            </template>
            <info-circle-outlined />
          </a-popover> -->
        </a-form-item>
        <a-form-item label="确认密码" name="confirmPassword">
          <a-input-password v-model:value="userForm.confirmPassword" autocomplete="off"/>
        </a-form-item>
      </a-form>
      <a-button type="primary" block @click="submitForm">确认修改</a-button>
    </div>
  </div>
</template>

<script>
import {getToken, removeToken} from '@/utils/auth'
import {mapMutations} from 'vuex'
import {updatePsw} from '@/api/common'

export default {
  name: 'ChangePw',
  data() {
    return {
      time: '00:00:00',
      formItemLayout: {
        // labelCol: { span: 6 },
        // wrapperCol: { span: 18 },
        layout: 'vertical'
      },
      userForm: {
        password: '',
        confirmPassword: '',
        os: '',
        redirect: '',
        initialPassword: false
      },
      redirect: '',
      rules: {
        password: [{
          required: true,
          message: '请输入符合规则的密码',
          trigger: 'blur',
          pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9]{6,12}$/
        }],
        confirmPassword: [{required: true, trigger: 'blur', validator: this.validateConfirmPw}]
      }
    }
  },
  created() {
    this.userForm.redirect = (this.$route.query && this.$route.query.redirect) || ''
    this.userForm.os = this.$route.query && this.$route.query.os
    this.userForm.initialPassword = Boolean(this.$route.query && this.$route.query.initialPassword) || false
    // if (!getToken()) {
    //   this.$router.push({ path: '/login', query: { os: this.userForm.os, redirect: encodeURIComponent(this.userForm.redirect) }})
    // }
    if (this.$route.query.tokenState) {
      this.userForm.token = this.$route.query.token
    }
    if (this.userForm.initialPassword) {
      removeToken()
      this.$message.warning('初始密码必须修改密码后才能进入后台', 5)
    }
    setInterval(() => {
      this.getTime()
    }, 1000)
  },
  methods: {
    getTime() {
      const date = new Date()
      const hour = date.getHours() < 10 ? '0' : date.getHours()
      const min =
          date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
      const sec =
          date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
      this.time = `${hour}:${min}:${sec}`
    },
    ...mapMutations('user', ['SET_TOKEN']),
    submitForm() {
      console.log('submit')
      this.$refs.userForm.validate().then(res => {
        if (res) {
          updatePsw(this.userForm).then(res => {
            console.log('updatePsw==>', res)
            if (res.code === 0) {
              this.$message.success('修改成功，请重新登录')
              this.resetStatus()
            }
          })
        }
      }).catch(err => {
        console.log('form validata err==>', err)
      })
    },
    handleCancel() {
      console.log('cancel')
      const _this = this
      this.$confirm({
        title: '提示',
        content: '关闭将退出登录！',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          _this.$refs.userForm.clearValidate()
          _this.userForm.password = ''
          _this.userForm.confirmPassword = ''
          _this.resetStatus()
        }
      })
    },
    async validateConfirmPw(rule, value) {
      if (!value) {
        return Promise.reject('请输入确认密码')
      } else {
        if (value === this.userForm.password) {
          return Promise.resolve()
        } else {
          return Promise.reject('两次密码不一致')
        }
      }
    },
    resetStatus() {
      this.SET_TOKEN('')
      removeToken()
      this.$router.push({path: '/login', query: this.$route.query})
    }
  }
}
</script>

<style lang="scss" scoped>
$bg: #fff;
$dark_gray: #185c87;
$light_gray: #185c87;

#chang-pw {
  height: 100%;
  width: 100%;
  background: $bg url(~@/assets/houlang/background.svg) no-repeat 50%;
  // background-color: $bg;
  overflow: hidden;
  background-size: 100%;
  padding: 200px 0;
  position: relative;

  .timer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    font-size: calc(24vw + 1em);
    font-weight: bold;
    color: #f7f7f7;
    line-height: 1;
  }

  .chang-pw-title {
    text-align: center;
    margin-bottom: 10px;
    height: 44px;
    line-height: 44px;

    .title {
      font-size: 30px;
      color: $light_gray;
      text-align: center;
      font-weight: bold;
    }

    .logo {
      height: 44px;
      vertical-align: top;
      margin-right: 16px;
      border-style: none;
    }
  }

  .chang-pw-box {
    /*padding: 160px auto;*/
    width: 35rem;
    position: relative;
    margin: 0 auto;
    overflow: hidden;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    border: 1px solid #ddd;
  }
}
</style>
