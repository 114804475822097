import requestPms from '@/utils/requestPms'

export function getCodeImg() {
  return requestPms({
    url: '/auth/v1/captcha/get',
    method: 'get'
  })
}
export function getPhoneCode(data) {
  return requestPms({
    url: '/auth/v1/code/send',
    method: 'post',
    data
  })
}
export function updatePsw(data) {
  return requestPms({
    url: '/user/v1/updatePsw',
    method: 'post',
    data
  })
}
