import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css"; // or 'ant-design-vue/dist/antd.less'
import "@/styles/index.scss"; // global css
import "@/styles/houlang.scss";
import installIcons from "./icons"; // icon
// import SvgIcon from "@/components/SvgIcon"; // svg component

installIcons(createApp(App));
createApp(App)
  .use(store)
  .use(router)
  .use(Antd)
  // .component("svg-icon", SvgIcon)
  .mount("#app");
