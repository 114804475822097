import axios from 'axios'
import { message } from 'ant-design-vue'
import store from '@/store'
import { getToken, removeToken } from '@/utils/auth'
// import { h } from 'vue'
import { getUUID } from '@/utils'
// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_PMS_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 40000, // request timeout
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json;charset=UTF-8',
    'Accept': '*/*'
    // 'Authorization': getToken()
  }
})

// request interceptor
service.interceptors.request.use(
  (config) => {
    // do something before request is sent
    config.headers['RequestId'] = getUUID()
    // console.log('token==>', store.state['user']['token'])
    if (store.state['user']['token'] && store.state['user']['token'] !== 'undefined') {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers['Authorization'] = getToken() || store.state['user']['token']
    }

    return config
  },
  (error) => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

  /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
  (response) => {
    const res = response.data

    if (res.code === 204) {
      removeToken()
    }
    if (res.code !== 0) {
      message.error({
        content: (res.msg || '接口请求异常') + '(requestId:' + res.requestId + ')',
        duration: 5
      })

      return Promise.reject(new Error(res.msg || 'Error'))
    } else {
      return res
    }
  },
  (error) => {
    console.log('err' + error) // for debug
    message.error({
      content: error.msg ?? '接口请求异常',
      duration: 5
    })
    return Promise.reject(error)
  }
)

export default service
