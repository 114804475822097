import { createRouter, createWebHashHistory } from 'vue-router'
import LoginView from '../views/login/index.vue'
import ChangePwView from '../views/changePw/index.vue'

const routes = [
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/changePw',
    name: 'changePw',
    component: ChangePwView
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
