import { login, logout, getInfo, getUserPms } from '@/api/user'
import { getToken, setToken, removeToken } from '@/utils/auth'
import router, { resetRouter } from '@/router'

const state = {
  token: getToken(),
  name: '',
  avatar: '',
  introduction: '',
  roles: [],
  userId: '',
  pms: []
}

const mutations = {
  SET_TOKEN: (state, token) => {
    console.log('token==>', token)
    state.token = token
  },
  SET_INTRODUCTION: (state, introduction) => {
    state.introduction = introduction
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
  },
  SET_USER_ID: (state, userId) => {
    state.userId = userId
  },
  SET_USER_PMS: (state, pms) => {
    state.pms = pms
  }
}

const actions = {
  // user login
  login({ commit }, userInfo) {
    const { username, password, os, code, codeKey, loginType, phoneNumber, phoneCode } = userInfo
    return new Promise((resolve, reject) => {
      let data = {}
      if (loginType === 1) {
        data = {
          username: username.trim(),
          password: password,
          os: os || 'cms',
          code,
          codeKey,
          loginType
        }
      } else if (loginType === 2) {
        data = {
          username: phoneNumber.trim(),
          password: phoneCode,
          os: os || 'cms',
          loginType
        }
      }
      login(data).then((response) => {
        // console.log('login res==>', response)
        const { data } = response
        commit('SET_TOKEN', data.token)
        if (!data.initialPassword) {
          setToken(data.token)
        }
        resolve(data)
      }).catch((error) => {
        console.log('login err==>', error)
        reject(error)
      })
    })
  },

  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getInfo({ platform: 0 })
        .then((response) => {
          const { data } = response

          if (!data) {
            reject('验证失败，请重新登录！')
          }

          const { id, nickName, position } = data
          // console.log(data);
          commit('SET_ROLES', ['admin'])
          commit('SET_NAME', nickName)
          commit(
            'SET_AVATAR',
            'https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif'
          )
          commit('SET_INTRODUCTION', position)
          commit('SET_USER_ID', id)
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // get user pms
  getUserPms({ commit, state }) {
    return new Promise((resolve, reject) => {
      getUserPms({ platform: 0 })
        .then((response) => {
          const { data } = response

          // console.log(data)

          if (!data) {
            reject('获取权限失败！')
          }
          commit('SET_USER_PMS', data)
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // user logout
  logout({ commit, state, dispatch }) {
    return new Promise((resolve, reject) => {
      logout(state.token)
        .then(() => {
          commit('SET_TOKEN', '')
          commit('SET_ROLES', [])
          removeToken()
          resetRouter()

          // reset visited views and cached views
          // to fixed https://github.com/PanJiaChen/vue-element-admin/issues/2485
          dispatch('tagsView/delAllViews', null, { root: true })

          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // remove token
  // resetToken({ commit }) {
  //   return new Promise((resolve) => {
  //     commit("SET_TOKEN", "");
  //     commit("SET_ROLES", []);
  //     removeToken();
  //     resolve();
  //   });
  // },

  // dynamically modify permissions
  async changeRoles({ commit, dispatch }, role) {
    const token = role + '-token'

    commit('SET_TOKEN', token)
    setToken(token)

    const { roles } = await dispatch('getInfo')

    resetRouter()

    // generate accessible routes map based on roles
    const accessRoutes = await dispatch('permission/generateRoutes', roles, {
      root: true
    })
    // dynamically add accessible routes
    router.addRoutes(accessRoutes)

    // reset visited views and cached views
    dispatch('tagsView/delAllViews', null, { root: true })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
