<template>
  <div id="login">
    <div class="timer">{{ time }}</div>
    <div class="login-box">
      <div class="login-title">
        <!--          <img class="logo" src="@/assets/houlang/logo.png" alt="">-->
        <span class="title">业务后台登录中心</span>
      </div>
      <a-form
          ref="form"
          :model="form"
          :rules="rules"
          class="login-form"
          auto-complete="on"
          label-position="left"
      >

        <a-tabs v-model:activeKey="form.loginType" type="card" centered animated @change="tabsChange">
          <a-tab-pane :key="1" tab="账密登录">
            <div v-if="form.loginType === 1">
              <a-form-item name="username">
                <span class="svg-container">
                  <svg-icon icon-class="user-2"/>
                </span>
                <a-input
                    ref="username"
                    v-model:value="form.username"
                    placeholder="Username"
                    name="username"
                    :bordered="false"
                />
              </a-form-item>

              <a-form-item name="password">
                <span class="svg-container">
                  <svg-icon icon-class="password"/>
                </span>
                <a-input-password
                    ref="password"
                    v-model:value="form.password"
                    class="hl_password"
                    placeholder="Password"
                    :bordered="false"
                    autocomplete="off"
                />
                <!-- <span class="show-pwd" @click="showPwd">
              <svg-icon
                :icon-class="passwordType === 'password' ? 'eye' : 'eye-open'"
              />
            </span> -->
              </a-form-item>

              <a-form-item name="code">
                <a-input
                    ref="code"
                    v-model:value="form.code"
                    onkeyup="value=value.replace(/[^\w\.\/]/ig,'')"
                    class="hl_code"
                    placeholder="Code"
                    :bordered="false"
                    autocomplete="off"
                >
                  <template #addonAfter>
                    <div class="code-img" @click="getCodeImg">
                      <img :src="codeImg.url" alt="">
                    </div>
                  </template>
                </a-input>
                <!-- <div id="HtmlInputScan">
                  <a-input
                    ref="code"
                    :value="form.code"
                    class="hl_code text"
                    placeholder="Code"
                    :bordered="false"
                    @input="input"
                    @keyup.enter="handleLogin"
                  />
                  <a-input ref="code_pw" v-model:value="form.code" :bordered="false" type="password" class="hl_code pass" @input="input" @keyup.enter="handleLogin">
                    <template #addonAfter>
                      <div class="code-img" @click="getCodeImg">
                        <img :src="codeImg.url" alt="">
                      </div>
                    </template>
                  </a-input>
                </div> -->
              </a-form-item>
            </div>
          </a-tab-pane>
          <a-tab-pane :key="2" tab="手机登录">
            <div v-if="form.loginType===2">
              <a-form-item name="phoneNumber">
                <span class="svg-container">
                  <svg-icon icon-class="user-2"/>
                </span>
                <a-input
                    ref="phoneNumber"
                    v-model:value="form.phoneNumber"
                    placeholder="PhoneNumber"
                    :bordered="false"
                />
              </a-form-item>
              <a-form-item name="phoneCode">
                <a-input
                    ref="phoneCode"
                    v-model:value="form.phoneCode"
                    class="hl_code"
                    :maxLength="6"
                    placeholder="Code"
                    :bordered="false"
                    autocomplete="off"
                    @keyup.enter="handleLogin"
                >
                  <template #addonAfter>
                    <div class="code-img">
                      <a-button type="primary" :loading="phoneCode.loading" :disabled="!phoneCode.sendCode"
                                @click="getPhoneCode">
                        {{ !phoneCode.sendCode ? `${phoneCode.authTime}秒后可重发` : '获取验证码' }}
                      </a-button>
                    </div>
                  </template>
                  <!-- <template #addonBefore>
                <div class="code-img" @click="getCodeImg">
                  <img :src="codeImg.url" alt="">
                </div>
              </template> -->
                </a-input>
              </a-form-item>
            </div>
          </a-tab-pane>
        </a-tabs>

        <a-button
            :loading="loading"
            type="primary"
            style="width: 100%; height: 47px;font-size: 18px;border-radius: 6px;"
            @click.prevent="handleLogin"
        >登录
        </a-button>
      </a-form>
    </div>
    <!--
    <a-tabs
      class="login-box"
      type="card"
      value="qrcode"
      :stretch="true"
    >
      <a-tab-pane label="钉钉扫码登录" name="qrcode">
        <div id="login_container" class="dingding_scan"/>
      </a-tab-pane>
      <a-tab-pane label="邮箱账号登录" name="email">

      </a-tab-pane>

    </a-tabs>-->
    <!-- <ChangePw :visible="changePwVisible" :login-data="tokenData" @close="changePwVisible=false" /> -->
  </div>
</template>

<script>
import {getToken} from '@/utils/auth'
// import store from "@/store";
import svgIcon from '@/components/SvgIcon'
// import options from "./options";
import Cookies from 'js-cookie'
import {getCodeImg, getPhoneCode} from '@/api/common'
// import ChangePw from '../changePw/changPwModal.vue'

export default {
  name: 'Login',
  components: {
    svgIcon
    // ChangePw
  },
  data() {
    const validatePassword = async (rule, value) => {
      if (value.length < 6) {
        return Promise.reject(new Error('密码不能小于6位！'))
      } else {
        return Promise.resolve()
      }
    }
    return {
      form: {
        username: '',
        password: '',
        os: '',
        code: '',
        codeKey: '',
        loginType: 1,
        phoneNumber: '',
        phoneCode: ''
      },
      rules: {
        username: [
          {required: true, trigger: 'blur', message: '账号不能为空'}
        ],
        phoneNumber: [
          {
            required: true,
            trigger: 'blur',
            message: '请输入正确的手机号',
            pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
          }
        ],
        password: [
          {
            required: true,
            trigger: 'blur',
            min: 6,
            validator: validatePassword
          }
        ],
        phoneCode: [{required: true, trigger: 'blur', pattern: /^[0-9]{6}$/, message: '请输入正确的验证码'}]
      },
      loading: false,
      passwordType: 'password',
      redirect: undefined,
      // options: options
      time: '00:00:00',
      codeImg: {
        url: '',
        key: ''
      },
      phoneCode: {
        sendCode: true,
        authTime: 60
      },
      changePwVisible: false,
      tokenData: {
        // token: '',
        // initialPassword: true,
        // ninetyDaysWithoutChangePassword: false
      },
      changePw: false, // 是否通过跳转来修改密码
      timer: undefined // 定时器
    }
  },
  watch: {
    $route: {
      handler: function (route) {
        this.redirect = (route.query && route.query.redirect) || ''
        this.form.os = route.query && route.query.os
        this.changePw = Boolean(route.query && route.query.changePw) || false
      },
      immediate: true
    }
  },
  created() {
    // this.ddLogin();
    this.autoLogin()
    setInterval(() => {
      this.getTime()
    }, 1000)
    if (!this.changePw) {
      this.getCodeImg()
    }
    this.initTimer()
  },
  methods: {
    showPwd() {
      if (this.passwordType === 'password') {
        this.passwordType = ''
      } else {
        this.passwordType = 'password'
      }
      this.$nextTick(() => {
        this.$refs.password.focus()
      })
    },
    ddLogin() {
      this.$nextTick(() => {
        const token = getToken()
        if (this.redirect.includes('http') && token) {
          this.$store.dispatch('user/getInfo').then(() => {
            window.location.href =
                this.redirect + token.replace(/houlang-token /g, '')
          })
        }
      })
    },
    handleLogin() {
      console.log('login form ==>', JSON.stringify(this.form))
      this.$refs.form.validate().then((res) => {
        if (res) {
          this.loading = true
          this.$route.query.tokenState = false
          this.$store.dispatch('user/login', this.form).then((data) => {
            // console.log('token第一登录测试==>', data)
            if (data.initialPassword) {
              // this.changePwVisible = true
              this.$route.query.token = data.token
              this.$route.query.tokenState = true
              // console.log('token第二登录测试==>', this.$route.query)
              this.$router.push({path: '/changePw', query: this.$route.query})
            } else {
              if (data.ninetyDaysWithoutChangePassword) {
                this.changePwTips(data)
              } else {
                this.jumpSystem(data)
              }
            }
            this.loading = false
          }).catch(() => {
            if (this.form.loginType === 1) {
              this.getCodeImg()
            }
            this.loading = false
          })
        } else {
          console.log('error submit!!')
          return false
        }
      }).catch(err => {
        console.log('validate err==>', err)
      })
    },
    autoLogin() {
      const token = getToken()
      if (this.changePw) {
        this.changePwVisible = true
        return
      }
      if (token) {
        this.$store.dispatch('user/getInfo').then(() => {
          if (this.redirect.includes('http')) {
            window.location.href = this.redirect + token
          } else {
            this.$router.push({path: this.redirect || '/'})
          }
        }).catch(() => {
          console.log('token error')
        })
      }
    },
    getTime() {
      const date = new Date()
      const hour = date.getHours() < 10 ? '0' : date.getHours()
      const min =
          date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
      const sec =
          date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
      this.time = `${hour}:${min}:${sec}`
    },
    getCodeImg() {
      getCodeImg().then(res => {
        console.log('codeImg==>', res)
        if (res.code === 0) {
          const {codeUrl, key} = res.data
          this.codeImg.url = codeUrl
          this.codeImg.key = key
          this.form.codeKey = key
        }
      })
    },
    getPhoneCode() {
      this.$refs.form.validateFields(['phoneNumber']).then(res => {
        this.phoneCode.loading = true
        getPhoneCode({phoneNumber: this.form.phoneNumber}).then(res => {
          console.log('code==>', res)
          if (res.code === 0) {
            this.$message.success('发送成功')
            this.phoneCode.loading = false
            const now = new Date().getTime()
            const endTime = 60 * 1000 + now
            Cookies.set('validityCodeTime', endTime, {expires: new Date(endTime)})
            this.phoneCode.sendCode = false
            this.phoneCode.authTime = 60
            const timer = setInterval(() => {
              this.phoneCode.authTime--
              if (this.phoneCode.authTime <= 0) {
                this.phoneCode.sendCode = true
                clearInterval(timer)
              }
            }, 1000)
          }
        }).catch(() => {
          this.phoneCode.loading = false
        })
      }).catch(err => {
        console.log('send code err==>', err)
      })
    },
    initTimer() {
      const endTime = Cookies.get('validityCodeTime')
      if (endTime) {
        this.phoneCode.authTime = parseInt((parseInt(endTime) - new Date().getTime()) / 1000)
        if (this.phoneCode.authTime > 0) {
          this.phoneCode.sendCode = false
          const timer = setInterval(() => {
            this.phoneCode.authTime--
            if (this.phoneCode.authTime <= 0) {
              this.phoneCode.sendCode = true
              clearInterval(timer)
            }
          }, 1000)
        }
      }
    },
    tabsChange() {
      this.form.password = ''
      this.form.username = ''
      this.form.code = ''
      this.form.phoneNumber = ''
      this.form.phoneCode = ''
      this.$refs.form.clearValidate()
    },
    // 实现双向绑定
    input(e) {
      this.$emit('input', e.target.value)
    },
    changePwTips(data) {
      const _this = this
      let countdown = 3
      const modal = this.$confirm({
        title: '提示',
        content: `已超过90天未修改密码，建议前往修改密码，即将在 ${countdown}s 后前往后台`,
        okText: '修改密码',
        cancelText: '前往后台',
        onOk: () => {
          clearInterval(this.timer)
          _this.$router.push({path: '/changePw', query: this.$route.query})
        },
        onCancel: () => {
          clearInterval(this.timer)
          _this.jumpSystem(data)
        }
      })
      this.timer = setInterval(() => {
        countdown > 0 ? countdown-- : countdown
        modal.update({
          content: `已超过90天未修改密码，建议前往修改密码，即将在 ${countdown}s 后前往后台`
        })
        if (countdown <= 0) {
          this.jumpSystem(data)
          clearInterval(this.timer)
          modal.destroy()
        }
      }, 1000)
    },
    jumpSystem(data) {
      if (this.redirect.includes('http')) {
        window.location.href = this.redirect + data.token
      } else {
        this.$router.push({path: this.redirect || '/'})
      }
    }
  }
}
</script>

<style lang="scss">
/* 修复input 背景不协调 和光标变色 */
/* Detail see https://github.com/PanJiaChen/vue-element-admin/pull/927 */

// $bg: #adc8db;
$bg: #fff;
$light_gray: #505050;
$cursor: #000000;
$inputBorder: #ccc;

@supports (-webkit-mask: none) and (not (cater-color: $cursor)) {
  .login-container .a-input input {
    color: $cursor;
  }
}

/* reset element-ui css */
#login {
  .ant-input {
    display: inline-block;
    height: 47px;
    width: 90%;

    background: transparent;
    border: 0px;
    -webkit-appearance: none;
    border-radius: 0px;
    padding: 12px 5px 12px 15px;
    color: $light_gray;
    height: 47px;
    caret-color: $cursor;

    &:-webkit-autofill {
      box-shadow: 0 0 0px 1000px $bg inset !important;
      -webkit-text-fill-color: $cursor !important;
    }

    &::placeholder {
      -webkit-text-security: none;
      color: #c8dae7;
      pointer-events: none;
    }
  }

  .ant-form-item-control-input-content {
    border: 1px solid $inputBorder;
    background: $bg;
    border-radius: 5px;
    color: #454545;
    display: flex;
  }

  .ant-input-group-addon,
  .ant-form-item-has-error .ant-input-group-addon, .ant-form-item-has-error .ant-input-number-group-addon {
    color: #454545;
    border: none;
  }
}

</style>

<style lang="scss" scoped>
// $bg: #3498db;
$bg: #fff;
$dark_gray: #185c87;
$light_gray: #185c87;

#login {
  height: 100%;
  width: 100%;
  background: $bg url(~@/assets/houlang/background.svg) no-repeat 50%;
  // background-color: $bg;
  overflow: hidden;
  background-size: 100%;
  padding: 200px 0;
  position: relative;

  .timer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    font-size: calc(24vw + 1em);
    font-weight: bold;
    color: #f7f7f7;
    line-height: 1;
  }

  .login-title {
    text-align: center;
    margin-bottom: 10px;
    height: 44px;
    line-height: 44px;

    .title {
      font-size: 30px;
      color: $light_gray;
      text-align: center;
      font-weight: bold;
    }

    .logo {
      height: 44px;
      vertical-align: top;
      margin-right: 16px;
      border-style: none;
    }
  }

  .login-box {
    /*padding: 160px auto;*/
    max-width: 350px;
    position: relative;
    margin: 0 auto;
    overflow: hidden;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    border: 1px solid #ddd;
  }

  .login-form {
    position: relative;
    /*width: 520px;*/
    /*max-width: 100%;*/
    /*padding: 160px 35px 0;*/
    margin: 0 auto;
    overflow: hidden;
  }

  /*.dingding_scan {*/
  /*  display: block;*/
  /*  width: auto;*/
  /*  margin: 0 auto;*/
  /*}*/

  .svg-container {
    padding: 6px 5px 6px 15px;
    color: $dark_gray;
    vertical-align: middle;
    width: 30px;
    display: inline-block;
    position: relative;
  }

  .show-pwd {
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 16px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
  }

  .hl_password {
    // display: flex;
    // display: inline-block;
    flex: auto;
    padding: 0;
  }

  .hl_code {
    flex: auto;
    padding: 0;

    ::v-deep .ant-input-group-addon {
      padding: 0;
    }

    .code-img {
      img {
        cursor: pointer;
        display: block;
        height: 47px;
        width: 110px;
      }

      ::v-deep .ant-btn {
        height: 47px;
      }
    }
  }

  .switch-login-type {
    display: flex;
  }
}

// @media screen and (max-width:1600px){
//   font-size: 60px;
// }
// @media screen and (max-width:1200px){
//   font-size: 30px;
// }
@media (max-width: 767px) {
  #login {
    height: auto;
    padding: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

::v-deep .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
  padding: 8px 47px;
}

#HtmlInputScan {
  position: relative;
  height: 49px;
  width: 100%;

  .text {
    position: absolute;
    width: 100%;
    // font-family: Consolas, Monaco, monospace;
  }

  .pass {
    position: absolute;
    z-index: 999999; //密码框放上面才能自动切换到英文
    width: 100%;
    // font-family: Consolas, Monaco, monospace;
    background-color: transparent;
    color: transparent;
  }
}
</style>
