// import request from '@/utils/request'
import requestPms from '@/utils/requestPms'

export function login(data) {
  return requestPms({
    url: '/auth/v1/login',
    method: 'post',
    data
  })
}

export function getInfo(data) {
  return requestPms({
    url: '/user/v1/user/get',
    method: 'post',
    data
  })
}

export function logout() {
  return requestPms({
    url: '/auth/v1/logout',
    method: 'post'
  })
}

export function getAuthMenu(data) {
  return requestPms({
    url: '/permission/v1/permission/user/list',
    method: 'post',
    data
  })
}

export function getUserPms(data) {
  return requestPms({
    url: '/permission/v1/permission/value/user/list',
    method: 'post',
    data
  })
}
